:root {
    --main-color : #fff;
    --color-dark : #1d2231;
    --text-grey : #8390A2;
}

.graph-create-temperatureGraph {
    margin-top: 50px;
}

.graph-container-wifiGraph {
    margin-bottom: 50px;
}

.container-xxl {
  width: 100%;
}

.graph-sidebar {
    width: 345px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: var(--main-color);
    z-index: 100;
    transition: width 300ms;
    justify-content: space-between;
}
.graph-sidebar-brand {
    padding :1rem 0rem 1rem 2rem;
    color: #222f;
}

.graph-sidebar-brand span {
    padding-right: 1rem;
}

.graph-sidebar-brand button {
    background: var(--main-color);
 //   border-radius: 10px;
    color: rgb(109, 107, 107);
    font-size: .8rem;
    padding: .5rem .1rem;
    border: 1px solid var(--main-color);
    cursor: pointer;
}

.graph-sidebar-brand button:hover{
    background-color: #ACCEF7;
    color: var(--main-color);
    width: auto;
}

.graph-titles {
    color: #222;

}

.logo-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img{
        height: 120px;
    }
}

.custom-date-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .date-picker{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }

    input{
        width: 15%;
        margin: 10px;
    }

    img{
        height: 80px;
        flex: 1;
    }
}

header {
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 1rem 1.5rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

}

header h4 {
    color: #222;
}

header label span {
    font-size: 1.7rem;
    padding-right: 1rem;
}

main {
    margin-top: 85px;
    padding: 2rem 1.5rem;
    background: #f1f5f9;
    min-height: calc(100vh - 90px);
}

table {
    border-collapse: collapse;
}

thead tr {
    border-top:  1px solid #f0f0f0;
    border-bottom: 2px solid #f0f0f0;
}

thead th {
    font-weight: 700;
}

td {
    font-size: .9rem;
    color: #222;

}

td .status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 1rem;
}

tr td:last-child {
    display: flex;
    align-items: center;
}

p {
    padding-left: 0%;
    text-align: left;
}
header {
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 1rem 1.5rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

}

header h4 {
    color: #222;
}

header label span {
    font-size: 1.7rem;
    padding-right: 1rem;
}
